/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Notification = {
    id: string;
    message: string;
    /**
     * Type of the notification, will be used to determine the styling of the notification on the FE
     */
    type: Notification.type;
    /**
     * If the notification can be dismissed by the user
     */
    dismissible: boolean;
};
export namespace Notification {
    /**
     * Type of the notification, will be used to determine the styling of the notification on the FE
     */
    export enum type {
        TRIAL = 'trial',
        SUCCESS = 'success',
        INFO = 'info',
        WARNING = 'warning',
        ERROR = 'error',
    }
}

