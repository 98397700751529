import { useCallback, useEffect, useState } from 'react'
import { Commit, RepositoryCommitManipulationService } from '../../api/coreapi'
import { uniqBy } from 'lodash'
import { log } from '../../utils/log'

const DEFAULT_BRANCH_REFS = ['dv.branch.1']

const timeout = (ms: number) => new Promise((_, reject) => setTimeout(() => reject(new Error('timeout')), ms))

export const useAllCommits = (repoId: string) => {
  const [commits, setCommits] = useState<Commit[]>([])

  const fetchCommitsByRefIds = useCallback(
    async (refIds: string[] = [], limit: number = 100) => {
      try {
        const result = await RepositoryCommitManipulationService.srcHandlersv2CommitListAll({
          repoId,
          refIds: refIds,
          limit: limit,
        })
        return 'items' in result ? result.items : []
      } catch (error) {
        log.error('Failed to fetch commits:', error)
        return []
      }
    },
    [repoId]
  )

  const fetchCommits = useCallback(async () => {
    const INITIAL_COMMIT_TO_FETCH_LIMIT = 30
    const [initialCommits, hardcodedCommits] = await Promise.all([
      fetchCommitsByRefIds([], INITIAL_COMMIT_TO_FETCH_LIMIT),
      fetchCommitsByRefIds(DEFAULT_BRANCH_REFS, INITIAL_COMMIT_TO_FETCH_LIMIT),
    ])

    let allCommits = [...initialCommits, ...hardcodedCommits]
    const parentCommitIds = allCommits
      .flatMap((commit) => commit.parents)
      .filter((parentId) => !allCommits.some((commit) => commit.commit_id === parentId))

    // invoke fetchCommitsByRefIds for every commit that has missing parent
    // While this could launch a lot of requests, the experience is better as we bring in more commits, resulting in a more complete graph
    // Than passing the list of parentCommitsIds to fetchCommitsByRefIds

    const moreCommits = await Promise.all(
      parentCommitIds.map(async (parentId) => {
        const LIMIT_FOR_PARTIAL_HISTORY_COMMIT = 20
        const FETCH_COMMIT_TIMEOUT = 3000
        try {
          return await Promise.race([
            fetchCommitsByRefIds([parentId], LIMIT_FOR_PARTIAL_HISTORY_COMMIT),
            timeout(FETCH_COMMIT_TIMEOUT),
          ])
        } catch {
          return [] as Commit[]
        }
      })
    ).then((results) => uniqBy((results as Commit[][]).flat(), 'commit_id'))

    allCommits = uniqBy([...allCommits, ...moreCommits], 'commit_id')
    allCommits.sort((a, b) => a.commit_id.localeCompare(b.commit_id))
    setCommits(allCommits)
  }, [fetchCommitsByRefIds])

  useEffect(() => {
    fetchCommits()
  }, [fetchCommits])

  return { commits, setCommits }
}
