import { UrlParams, useUrlState } from '../hooks/useUrlState'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { usePageTitle } from '../hooks/usePageTitle'
import { Outlet } from 'react-router'
import compact from 'lodash/compact'
import { useAgentKeepAlive } from '../desktop/hooks/useAgentKeepAlive'
import { useLocalStorage } from 'usehooks-ts'
import { useUserInfo } from '../hooks/api/useUserInfo'
import { DefaultService, Tier } from '../api/coreapi'
import { milliseconds } from 'date-fns'
import { SetUserTierDialog } from './dialogs/SetUserTierDialog'
import { log } from '../utils/log'
import { errorToast } from '../utils/toast'

const TITLE_PREFIX = 'Diversion'
const A_DAY_IN_MILLIS = milliseconds({ days: 1 })
export const CommonRootElement = () => {
  const params = useUrlState()

  const formattedTitle = useMemo(() => {
    const title = urlParamsToTitle(params)
    return title ? `${TITLE_PREFIX} - ${title}` : title
  }, [params])
  usePageTitle(formattedTitle)

  const [isUserTierDialogOpen, setIsUserTierDialogOpen] = useState<boolean>(false)
  const [lastTierPopup, setLastTierPopup] = useLocalStorage<number>('last_tier_popup', Date.now())
  const { data: userInfo } = useUserInfo({ refreshInterval: A_DAY_IN_MILLIS })

  useEffect(() => {
    if (userInfo && userInfo.tier === Tier.UNKNOWN) {
      const now = Date.now()
      if (now - lastTierPopup > A_DAY_IN_MILLIS) {
        setLastTierPopup(now)
        setIsUserTierDialogOpen(true)
      }
    }
  }, [lastTierPopup, setLastTierPopup, userInfo])

  const onTierSelect = useCallback(async (tier: Tier) => {
    try {
      await DefaultService.srcHandlersv2AccountUpdateTier({
        requestBody: { tier: tier },
      })
    } catch (e) {
      log.error('Failed to update user tier:', e)
      errorToast('Failed to update user tier. Please contact support.')
      return
    }
  }, [])

  //Initiating the agent keep alive hook here to apply to all pages
  useAgentKeepAlive()

  return (
    <>
      <SetUserTierDialog isOpen={isUserTierDialogOpen} setOpen={setIsUserTierDialogOpen} onConfirm={onTierSelect} />
      <Outlet />
    </>
  )
}

const urlParamsToTitle = (params: UrlParams) => {
  const { repoId, mergeId, conflictId, branchId, commitId, compareCommitId, workspaceId, '*': filePath } = params
  if (conflictId) {
    return `Resolving conflict ${shortId(conflictId)}`
  }
  if (mergeId) {
    return `Resolving merge conflicts ${shortId(mergeId)}`
  }
  if (commitId && compareCommitId) {
    return `Comparing ${shortId(commitId)} to ${shortId(compareCommitId)}`
  }

  const titleParts: string[] = []
  if (repoId || workspaceId) {
    titleParts.push(shortId(workspaceId || repoId || ''))
  }
  if (branchId || commitId) {
    titleParts.push(shortId(commitId || branchId || ''))
  }
  if (filePath) {
    titleParts.push(`'${filePath}'`)
  }

  return compact(titleParts).join(' - ')
}

const shortId = (id: string) => {
  const parts = id.split('.')
  if (parts.length !== 3) {
    return id
  }
  return `${parts[0]}.${parts[1]}.${parts[2]!.split('-')[0]}`
}
