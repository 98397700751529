import { Error as CoreError, RepositoryWorkspaceManipulationService, WorkspaceStatus } from '../../api/coreapi'
import { flatten } from 'lodash'
import isEmpty from 'lodash/isEmpty'
import { useApi } from '../useApi'
import { useCallback } from 'react'

export const useWorkspaceStatus = (viewId: string, repoId: string, refId: string) => {
  const workspaceStatusCallback = useCallback(() => {
    return fetchWorkspaceStatus(repoId, refId)
  }, [repoId, refId])
  return useApi([viewId, 'workspaceStatus', 'repos', repoId, 'refs', undefined, refId], workspaceStatusCallback, true)
}

const isCoreError = (data: CoreError | WorkspaceStatus): data is CoreError => (data as CoreError).status !== undefined
const workspaceStatusItemLimit = 1500
export const fetchWorkspaceStatus = async (repoId: string, refId: string) => {
  let offset = 0
  const limit = workspaceStatusItemLimit
  let cascadedChangesCount = 0
  const items = []
  const conflicts = []
  let hasMore = false
  do {
    const res = await RepositoryWorkspaceManipulationService.srcHandlersv2WorkspaceGetStatus({
      repoId,
      workspaceId: refId,
      limit: limit,
      skip: offset,
      allowTrim: false,
    })
    if (isCoreError(res)) {
      throw res
    }
    const workspaceStatus = res as WorkspaceStatus
    cascadedChangesCount += workspaceStatus.changed_items_count
    const currentItemsFetched = flatten(Object.values(workspaceStatus.items || {}))
    items.push(...currentItemsFetched)
    conflicts.push(...(workspaceStatus.conflicts || []))
    offset += currentItemsFetched.length
    hasMore = currentItemsFetched.length === limit
  } while (hasMore)

  return {
    items,
    cascadedChangesCount,
    hasConflict: !isEmpty(conflicts),
  }
}
