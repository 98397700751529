import Alert from '@mui/material/Alert'
import { Notification } from '../../api/coreapi'
import styled from '@emotion/styled'

type NotificationMessageProps = {
  notification: Notification
}

const StyledAlert = styled(Alert)({
  display: 'flex',
  alignItems: 'center',
  borderRadius: 0,
})

function NotificationMessage({ notification }: NotificationMessageProps) {
  // TODO(DIV-6149): Change the color of the alert based on the notification type (and handle types outside of severity)
  return (
    <StyledAlert severity="info" key={notification.id}>
      {notification.message}
    </StyledAlert>
  )
}

type NotificationBarProps = {
  notifications: Notification[]
}
export const NotificationsBar = ({ notifications }: NotificationBarProps) => {
  return (
    <>
      {notifications.map((notification) => (
        <NotificationMessage key={notification.id} notification={notification} />
      ))}
    </>
  )
}
