import { useItemListApi } from '../useApi'
import { Notification, DefaultService } from '../../api/coreapi'
import { useMemo } from 'react'
import { sortBy } from 'lodash'

export const useNotifications = () => {
  const {
    data: notifications,
    loading,
    refresh,
  } = useItemListApi<Notification>(['notifications'], () => DefaultService.srcHandlersv2NotificationsListAll())
  const sortedNotifications = useMemo(
    () => (notifications ? sortBy(notifications, (notification) => notification.message) : notifications),
    [notifications]
  )
  return { notifications: sortedNotifications, loading, refresh }
}
