import { useApi } from '../../hooks/useApi'
import { RepositoryWorkspaceManipulationService, Workspace } from '../../api/coreapi'
import { createContext, useEffect } from 'react'
import { milliseconds } from 'date-fns'
import { log } from '../../utils/log'

type WorkspaceRevision = {
  workspaceRevision?: number
  refresh?: () => void
}

export const WorkspaceRevisionContext = createContext<WorkspaceRevision>({})

const WORKSPACE_REVISION_REFRESH_INTERVAL: Duration = { seconds: 10 }

const getFullRevision = (workspace?: Workspace) => (workspace ? workspace.journal_ordinal_id : undefined)

export const useWorkspaceRevisionUpdater = (
  repoId: string | undefined,
  workspaceId: string | undefined,
  setWorkspaceRevision: (revision: number) => void
) => {
  const { data: workspaceDetails, refresh } = useApi(
    ['repos', repoId, 'workspaces', workspaceId],
    repoId && workspaceId
      ? () =>
          RepositoryWorkspaceManipulationService.srcHandlersv2WorkspaceGetWorkspace({
            repoId,
            workspaceId,
          })
      : null,
    false,
    {
      refreshInterval: milliseconds(WORKSPACE_REVISION_REFRESH_INTERVAL),
    }
  )

  const workspaceRevision = getFullRevision(workspaceDetails)
  useEffect(() => {
    if (workspaceRevision) {
      log.info('Discovered new workspace revision', { repoId, workspaceId, workspaceRevision })
      setWorkspaceRevision(workspaceRevision)
    }
  }, [repoId, setWorkspaceRevision, workspaceId, workspaceRevision])

  return { refresh }
}
